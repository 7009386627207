import httpCommon from '@/plugins/http-common'

export default {
  state: {
    lockers: {},
    doors: {},
    cards: {},
    users: {},
  },
  getters: {
    users: state => state.users,
    getUserByID: function (state, id) {
      return state.users.find(obj => {
        return obj._id === id
      })
    },

    lockers: state => state.lockers,
    getLockerByID: function (state) {
      return (id) => {
        return state.lockers.find(obj => obj._id === id)
      }
    },

    doors: state => state.doors,
    getDoorByID: function (state) {
      return (id) => {
        var found = state.doors.items.find(obj => obj._id === id)
        if (found) return found.door_label
      }
    },

    cards: state => state.cards,
    getCardByID: function (state) {
      return (id) => {
        return id
        // return state.cards.find(obj => obj._id === id)
      }
    },

  },
  mutations: {
    SET_USERS (state, payload) {
      state.users = payload
    },

    SET_LOCKERS (state, payload) {
      state.lockers = payload
    },
    SET_DOORS (state, payload) {
      state.doors = payload
    },
    SET_CARDS (state, payload) {
      state.cards = payload
    }
    ,

  },
  actions: {
    async getUsers ({ commit }) {
      return httpCommon.get('data/users/').then(response => {
        commit('SET_USERS', response.data)
      })
    },
    async getLockers ({ commit }) {
      return httpCommon.get('data/lockers/').then(response => {
        commit('SET_LOCKERS', response.data)
      })
    },

    async getDoors ({ commit }) {
      return httpCommon.get('data/doors/?limit=-1&sort_dir[]=0').then(response => {
        commit('SET_DOORS', response.data)
      })
    },

    async getCards ({ commit }) {
      return httpCommon.get('data/cards/').then(response => {
        commit('SET_CARDS', response.data)
      })
    }
    ,

  }
  ,
}
